.usc_msg {
  pre {
    ul li::before {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: $black;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
    ol li::before {
     // counter-increment: my-sec-counter;
      //content: "" counter(my-sec-counter) ". ";
      list-style: auto;
      display: list-item;
      list-style-position: inside;
    }
    ol li {
    //  list-style: gujrati;
    list-style: auto;
    display: list-item;
    list-style-position: inside;
    }
  }
}
