.faqs_section{
    padding: 55px 15px 59px;
    max-width: 930px;

    @extend .mx_auto;
    
    @media (max-width: $phone) {
        padding: 20px 14px 30px;
      }
    .faqs_title_main{
        color: #990000;
        margin-bottom: 35px;
        font-size: 3rem;
        @media (max-width: $phone) {
            line-height: 3rem;
          }
    }
    .faqs_container{
        .faqs_title{
          color: #990000;
          margin-bottom: -19px;
        }
        .faqs_question{
            margin: 30px 0;
            .faqs_question_title{
                text-align: start;
                font-size: 20px;
                font-weight: bold;
            }
            .faqs_question_ans{
                margin: 3px 0;
                .faqs_question_ans_answer{
                    margin-bottom: 10px 0;
                    // font-size: 17px;
                }
                .faqs_question_ans_ul{
                    margin: 13px 0;
                    padding-left: 5rem;
                    li{
                        list-style: disc;
                        // font-size: 17px;
                       
                    }
                }
            }
        }
      }
    
}

.end_anq{
    text-transform: capitalize;
}
.end_and{
    margin: 0px !important;
}