.course_box_skull {
  .ant-skeleton {
    height: 100%;
    width: 100%;
    .ant-skeleton-avatar {
      clip-path: polygon(7% 0, 93% 0, 100% 8%, 100% 92%, 93% 100%, 7% 100%, 0 92%, 0 8%);
      height: 100%;
      width: 100%;
    }
  }
}

.banner-skull {
  background: unset;
  &::before {
    background: unset;
  }
  .ant-skeleton {
    width: 100%;
    .ant-skeleton-avatar {
      width: 100%;
      height: 100%;
      min-height: 350px;
    }
  }
}

.about-skull {
  .courseDetail_title {
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 20rem;
          border-radius: 0;
          height: 2rem;
        }
      }
    }
  }
  p {
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
         li {
           border-radius: 0;
           height: 1.5rem;
         }
        }
        .ant-skeleton-paragraph > li + li {
          margin-top: 0.5rem;
        }
      }
    }
  }
  ul {
    li {
      width: 20rem;
      .ant-skeleton-title {
        border-radius: 0;
        margin: 0;
      }
    }
  }
  .course_intro_wrap {
    .courseIntro {
      .courseIntro_img {
        .ant-skeleton-avatar {
          height: 12rem;
          width: 12rem;
        }
      }
      .courseIntro_title {
        .ant-skeleton-content {
          display: flex;
          justify-content: center;
          .ant-skeleton-title {
            height: 1.5rem;
            width: 25rem;
            border-radius: 0;
          }
        }
      }
      .courseIntro_subtitle {
        max-width: 100%;
        .ant-skeleton-content {
          display: flex;
          justify-content: center;
          .ant-skeleton-title {
            height: 1rem;
            width: 15rem;
            border-radius: 0;
            margin: 0;
          }
        }
      }
      .courseIntro_prof {
        margin-top: 0.2rem;
        .ant-skeleton {
          .ant-skeleton-content {
            display: flex;
            justify-content: center;
            .ant-skeleton-paragraph {
              li {
                width: 25rem !important;
                border-radius: 0;
                margin: 0.5rem 0 0;
                height: 1rem;
                &:last-child {
                  width: 15rem !important;
                }
              }
            }
          }
        }
      }
      .courseIntro_content {
        .ant-skeleton {
          .ant-skeleton-content {
           .ant-skeleton-paragraph {
             li {
               margin-top: 0.5rem;
               height: 1.5rem;
               border-radius: 0;
             }
           }
          }
        }
      }
    }
  }
}

.outline_skull {
  .course_syll_container {
    .syll_title {
      .ant-skeleton-title {
        width: 100%;
        max-width: 40rem;
        border-radius: 0;
        height: 1.5rem;
        @media (max-width: 568px) {
          width: 20rem;
        }
      }
    }
    .syll_con {
      .ant-skeleton-paragraph {
          li {
            height: 1.3rem;
            border-radius: 0;
            margin-top: 0.5rem;
          }
      }
    }
  }
  .syllabus_ouline_wrap {
    .syll_outline {
      .syll_outline_title {
        span {
          width: 30rem;
          .ant-skeleton-title {
            height: 1.5rem;
            margin: 0;
            border-radius: 0;
          }
        }
        .status {
          width: 5rem;
        }
      }
      .syll_outline_content {
        padding: 1rem 0 0 2.5rem !important;
        .syll_outline_exp {
          margin: 0 !important;
          .ant-skeleton-title {
            margin: 0 5rem 0 0;
            border-radius: 0;
            height: 1rem;
            width: 40rem;
          }
          .main_status {
            width: 5rem;
          }
          .status {
            width: 5rem;
          }
        }
      }
    }
  }
}

.faq-skull {
  .course_syll_container {
    .syll_title {
      .ant-skeleton-title {
        height: 1.5rem;
        border-radius: 0;
      }
    }
    .syll_con {
      .ant-skeleton-paragraph {
        li {
          height: 1rem;
          border-radius: 0;
          margin-top: 0.5rem;
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
  .faq_course_list {
    li {
      h2 {
        .ant-skeleton-title {
          height: 1.7rem;
          border-radius: 0;
        }
      }
      p {
        .ant-skeleton-paragraph {
          li {
            height: 1.2rem;
            border-radius: 0;
            margin: 0.5rem 0 0;
          }
        }
      }
    }
  }
}

.post_skull {
  .usc_ad_profile {
    h4 {
      .ant-skeleton-title {
        height: 1.2rem;
        width: 15rem;
        margin: 0;
        border-radius: 0;
      }
    }
    span {
      .ant-skeleton-title {
        height: 1rem;
        width: 10rem;
        margin: 0.5rem 0 0;
        border-radius: 0;
      }
    }
  }
  .usc_msg {
    p {
      .ant-skeleton-paragraph {
        li {
          height: 1rem;
          margin: 0.5rem 0 0;
          border-radius: 0;
        }
      }
    }
  }
}