.not_found_Page {
  width: 100%;
  max-width: 36.8rem;
  @extend .mx_auto;
  padding: 16rem 0;
  .not_found_content {
    @extend .d_flex_center;
    flex-direction: column;
    .notfound {
      img {
        width: 9.8rem;
        height: 13rem;
        @extend .img_object_center;
      }
    }
    h3 {
      font-weight: 600;
      @extend .fs_40;
      line-height: 4.7rem;
      @extend .text-center;
      color: $gray_thik;
      @extend .text_uppercase;
      @extend .mb_3;
      @media screen and (max-width: $ipad - 1) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    p {
      @extend .national_light;
      @extend .fs_20;
      line-height: 2.3rem;
      @extend .text_center;
      color: $silver;
      @media screen and (max-width: $ipad - 1) {
        font-size: 16px;
        line-height: 19px;
        padding: 0 3rem;
      }
    }
  }
}
