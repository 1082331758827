.main_header {
  // background: linear-gradient(
  //   270deg,
  //   #f0f0f0 0%,
  //   rgba(240, 240, 240, 0.75) 50.17%,
  //   rgba(240, 240, 240, 0.25) 60.22%,
  //   rgba(216, 216, 216, 0) 81.61%,
  //   rgba(196, 196, 196, 0) 100%
  // );
  background: #F1F1F1;
  height: 8.6rem;
  @media (max-width: $phone_landscape) {
    height: auto;
    background: transparent;
  }
  .container {
    @media (max-width: $phone_landscape) {
      padding: 0;
    }
    .top_header {
      @extend .d_flex;
      @extend .align_items_center;
      @extend .justify_content_between;
      padding: 2.3rem 0;
      @media (max-width: $phone_landscape) {
        padding-top: 0;
        padding-bottom: 0px;
        flex-direction: column;
      }
      .content_head {
        // max-width: 410px;
        width: 100%;
        margin-right: auto;
        @media (max-width: $phone_landscape) {
          order: 3;
          width: auto;
          position: absolute;
          left: 30px;
          top: 16px;
        }
        h1 {
          @extend .fs_32;
          line-height: 3.8rem;
          @extend .text_uppercase;
          color: $black;
          @extend .fw_600;
          @media screen and (max-width: $ipad_landscape) {
            font-size: 25px;
          }
          @media (max-width: $phone_landscape) {
            font-size: 16px;
            line-height: 19px;
            text-align: left;
          }
        }
      }
      .header_menu {
        margin-left: auto;
        margin-right: 50px;
        @extend .d_flex;
        @extend .align_items_center;
        @media screen and (max-width: $ipad_landscape) {
          margin-right: 15px;
        }
        @media (max-width: $phone_landscape) {
          width: 100%;
          height: 50px;
          justify-content: flex-end;
          padding-right: 30px;
          margin: 0 0 0px; /*30*/
          order: 2;
        }
        p {
          @extend .national_semibold;
          font-size: 15px;
          line-height: 18px;
          @extend .text_uppercase;
          color: $black;
          margin-right: 15px;
          text-align: right;
          white-space: nowrap;
        }
        .logout_text a {
          @extend .national_regular;
          font-size: 15px;
          line-height: 18px;
          @extend .text_uppercase;
          color: #1976d2;
          white-space: nowrap;
          &:hover {
            color: $black;
          }
        }
      }
      .logo {
        @extend .d_flex_center;
        @media (max-width: $phone_landscape) {
          order: 1;
          display: block;
          width: 100%;
          text-align: right;
          background: linear-gradient(
            270deg,
            #f0f0f0 0%,
            rgba(240, 240, 240, 0.75) 58.33%,
            rgba(240, 240, 240, 0.25) 72.92%,
            rgba(216, 216, 216, 0) 86.98%,
            rgba(196, 196, 196, 0) 100%
          );
          padding: 15px 30px 15px 0;
        }
        img {
          width: 12.2rem;
          height: 4rem;
          @extend .img_object_center;
          @extend .cursor_pointer;
          @media (max-width: $phone_landscape) {
            width: 62px;
            height: 20px;
          }
        }
        .Certificate_Logo {
          width: 216px;
          height: 4rem;
          @extend .img_object_center;
          @extend .cursor_pointer;
          @media (max-width: $phone_landscape) {
            width: 119px;
            height: 30px;
          }
        }
       
      }
    }
  }
  &.cartificate_header {
    .top_header {
      .content_head {
        margin: auto;
        position: unset;
        // padding-top: 2rem;
        @media (max-width: $phone_landscape) {
          margin: unset;
          width: 100%;
        }
      }
      .art_logo {
        // padding: 0 15px;
        img {
          width: 100%;
          height: 100%;
          @media (max-width: $phone_landscape) {
            width: 70%;
            height: 100%;
          }
        }
        &.new_art_logo {
          padding: 0 15px;
          @extend .justify_content_start;
          @media (max-width: $phone_landscape) {
            width: 100%;
            padding: 15px 30px;
            padding-top: 0;
            text-align: left;
          }
          img {
            width: 100%;
            max-width: 38rem;
            height: 100%;
            @media (max-width: $phone_landscape) {
              width: 100%;
              max-width: 21rem;
            }
          }
        }
      }
      
    }
  }
}
