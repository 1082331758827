$white:#ffffff;
$black:#000000;
$maroon:#990000;
$gray_input:#f2f2f2;
$gray:#C4C4C4;
$light_gray:#E2E2E2;
$dark_gray:#494949;
$thik_gray:#E2E2E2;
$yellow: #FACD04;
$black_shade: #333333;
$blue_light: #65b5ff;
$light_gray_think: #f0f0f0;
$light_gray_ii: #f2f2f2;
$gray_thik: #494949;
$silver: #aaaaaa;


$iphone: 320px;
$iphone_ii: 376px;
$phone: 576px;
$phone_landscape: 767px;
$ipad: 768px;
$ipad_landscape: 992px;
$ipadPro: 1024px;
$desktop_sm: 1200px;
$desktop: 1400px;
